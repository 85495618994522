/* TODO: either remove global colors at all or update all used colors to use them */

:root {
  --dark-mode: true;
  --navbar-bg-color: #48bbbe;
  --navbar-text-color: white;
  --primary-color: #007db5;
  --secondary-color: #fff;
  --header-bg-color: rgba(37, 34, 49, 0.9);
  --header-text-color: #fff;
  --btn-primary-color: #007db5;
  --btn-secondary-color: black;
  --font-family: Roboto, sans-serif;
}

:root {
  font-size: 14px;
  --main-text-color: rgba(0, 0, 0, 0.6);
  --main-text-hover: rgba(0, 0, 0, 1);
  --main-text-active: rgba(255, 255, 255, 1);
  --main-text-active-background: rgba(37, 34, 49, 0.9);
  --label-text-color: rgba(51, 51, 51, 0.87);
  --hightlight-text-color: #363b3d;
  --info-text-color: #939ea8;
  --additional-info-color: #566879;
  --cta-color: #007db5;
  --cta-color-active: white;
  --icon-color: #4281d1;
  --link-color: #007db5;
  --button-color: #2d74cc;
  --button-color-light: rgba(0, 0, 0, 0);
  --button-color-light-hover: #eeeeee;
  --border-color: #e9eaec;
  --input-border-color: #e6e6e6;
  --select-color: #56697a;
  --popover-text-color: #5d687a;
  --background-dark-color: #fcfcfc;
  --background-light-color: #ffffff;

  --rangepicker-weekday-caption: #333333;
  --rangepicker-cell-end: #43a4f1;
  --rangepicker-cell-end-text: rgba(255, 255, 255, 0.8);
  --rangepicker-cell-span: rgba(67, 164, 241, 0.21);
  --rangepicker-cell-disabled: #eeeeee;
  --rangepicker-cell-disabled-text: #b2b2b2;
}

html:focus-within {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
  color: var(--main-text-color);
  margin: 0;
  font-family: var(--font-family);
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.27px;
}
/* Define styles for the alert component */

h1 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-family: Roboto, sans-serif;
  color: rgba(51, 51, 51, 0.87);
  font-size: 96px;
  line-height: 96px;
  font-weight: 300;
  text-align: left;
  letter-spacing: -1.5px;
}

h2 {
  margin-top: 16px;
  margin-bottom: 32px;
  opacity: 1;
  font-family: Roboto, sans-serif;
  color: rgba(51, 51, 51, 0.87);
  font-size: 60px;
  line-height: 56px;
  font-weight: 300;
  letter-spacing: -0.5px;
}

h3 {
  margin-top: 16px;
  margin-bottom: 16px;
  font-family: Roboto, sans-serif;
  color: rgba(51, 51, 51, 0.87);
  font-size: 48px;
  line-height: 40px;
  font-weight: 400;
}

h4 {
  max-width: 770px;
  margin-top: 16px;
  margin-bottom: 16px;
  font-family: Roboto, sans-serif;
  color: rgba(73, 73, 73, 0.87);
  font-size: 34px;
  line-height: 32px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

h5 {
  margin-top: 8px;
  margin-bottom: 16px;
  font-family: Roboto, sans-serif;
  color: rgba(73, 73, 73, 0.87);
  font-size: 24px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: -1px;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 1;
  font-family: Roboto, sans-serif;
  color: #494949;
  font-size: 20px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
  text-transform: none;
}

p {
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  line-height: 28px;
}

a {
  display: inline;
  color: #007db5;
  line-height: 24px;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

ul {
  margin-top: 0px;
  margin-bottom: 8px;
  padding-left: 36px;
}

img {
  display: inline-block;
  max-width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
  font-family: Roboto, sans-serif;
  color: #333;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
}

blockquote {
  margin-bottom: 16px;
  padding: 16px;
  border-left: 4px solid #00aff5;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
}

.modal-open .modal {
  padding: 0 !important;
}

.gallery-figcaption
  .gallery-figcaption--thumbnails
  .caption-thumbnails
  ul.thumbnails-list
  li
  button.thumbnail-button {
  border: 0;
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 64px;
    line-height: 60px;
    font-weight: 400;
  }

  h2 {
    font-size: 52px;
    line-height: 48px;
  }

  h3 {
    font-size: 32px;
    line-height: 32px;
  }

  h4 {
    font-size: 28px;
    line-height: 28px;
  }
}
.dropdown-toggle {
  color: var(--navbar-text-color);
}
.dropdown-toggle:hover {
  color: #fff;
}
.carousel .carousel-indicators li {
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

/* START: swiper */
/* .swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff; */

/* Center slide text vertically */
/* display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

/* END: swiper */
